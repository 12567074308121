.menu {
  @media screen and (max-width: 1023px) {
    position: absolute;
    left: 0;
    right: 0;
  }
}
.menuItem {
  letter-spacing: 3px;
  opacity: 0.8;
  transition: opacity 1s ease;

  &:hover {
    opacity: 1;
  }
}
